.btn-group {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 0px;
  dxo-button + dxo-button {
    margin-left: 24px;
  }
}

.btn-group:has(dxo-button:only-child) {
  justify-content: right;

  &.left-single {
    justify-content: left;
  }
}
